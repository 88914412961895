import React, { Component } from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bona from "../../utils/TabulaRx.png";
import psga from "../../utils/RxConnexion.png";
import axios from "axios";
import {
  faUser,
  faChartBar,
  faHospital,
  faUserMd,
  faUserNurse,
  faPrescriptionBottle,
  faChartLine,
  faClinicMedical,
  faChartPie,
  faNotesMedical,
  faTools,
  faDatabase,
  faBars,
  faEnvelope,
  faBriefcase,
  faShoppingCart,
  faCapsules,
  faTruck,
  faPodcast,
  faMailBulk,
  faReceipt,
  faBook,
  faHandHoldingHeart,
  faCalculator,
} from "@fortawesome/free-solid-svg-icons";
import pdfAudit from "../../utils/Pharmacy Self-Audit Checklist.pdf";
import $ from "jquery";
import moment from "moment";
import { availableSolutions } from "./../../utils/constants";
const location = [
  "/landing",
  "/solution-diabuddy",
  "/solution-xpedicare",
  "/solution-bonarx",
  "/solution-alererx",
  "/solution-rxreach",
  "/solution-tabularx",
  "/solution-rxconnexion",
  "/solution-podcast",
  "/card-info",
  "/solution-coaching",
];
const css = `
    .offset-2 {
        margin-left: 60px;
    }`;
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      userInfo: {},
      subscriptionList: [],
    };
  }

  componentDidMount() {
    if (location.includes(window.location.pathname)) {
      this.getUserInfo();
    }
  }

  getUserInfo = () => {
    let filterData = {
      page_number: 1,
      page_size: 20,
      sort_order: { column: "_id", order: "desc" },
    };
    filterData.search = {
      email: this.props.auth.user.final_user,
    };
    axios
      .post("/api/user-data", filterData)
      .then((res) => {
        this.setState(
          {
            userInfo: res.data.records[0],
          },
          () => this.updateSubscriptionList()
        );
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  updateSubscriptionList = () => {
    let subscriptionList = [];
    const { userInfo } = this.state;
    availableSolutions.map((eachSol) => {
      if (
        userInfo[eachSol.subId] &&
        userInfo[eachSol.expiry] !== undefined &&
        userInfo[eachSol.expiry] >= moment().format()
      ) {
        subscriptionList.push(eachSol.name);
      }
    });
    if (
      userInfo["xpedicare_subscriptionId"] &&
      userInfo["expiration_xpedicare"] !== undefined &&
      userInfo["expiration_xpedicare"] >= moment().format()
    ) {
      subscriptionList.push("XPedicareRx");
    }
    this.setState({ subscriptionList });
  };

  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  handleToggleSidebar = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  collapseOther = (e, type) => {
    if (
      e.target.parentNode.parentNode.id !== "drugs" &&
      e.target.parentNode.parentNode.id !== "report"
    ) {
      if (type === "parent") {
        setTimeout(() => {
          $("li.pro-menu-item.pro-sub-menu").removeClass("open");
          $(".react-slidedown.pro-inner-list-item").addClass("closed");
        }, 100);
      } else if (type === "child") {
        let current = e.target;
        setTimeout(() => {
          $("li.pro-menu-item.pro-sub-menu").removeClass("open");
          $(".react-slidedown.pro-inner-list-item").addClass("closed");
          current.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.classList.remove(
            "closed"
          );
          current.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.classList.add(
            "open"
          );
        }, 100);
      } else if (type === "childchild") {
        let current = e.target;
        setTimeout(() => {
          $("li.pro-menu-item.pro-sub-menu").removeClass("open");
          $(".react-slidedown.pro-inner-list-item").addClass("closed");
          current.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.classList.remove(
            "closed"
          );
          current.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.classList.add(
            "open"
          );
          current.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.classList.remove(
            "closed"
          );
          current.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.classList.add(
            "open"
          );
        }, 100);
      } else {
        let current = e.target;
        $("li.pro-menu-item.pro-sub-menu").removeClass("open");
        $(".react-slidedown.pro-inner-list-item").addClass("closed");

        current.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.classList.remove(
          "closed"
        );
        current.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.classList.add(
          "open"
        );
      }
    }
  };
  redirectTo = (solution) => {
    const { userInfo } = this.state;
    if (solution === "TabulaRx") {
      if (
        (userInfo.expiration_tabula &&
          userInfo.expiration_tabula >= moment().format()) ||
        this.props.auth.user.superadmin
      ) {
        window.location.href = "/dashboard";
      } else if (
        userInfo.expiration_tabula &&
        userInfo.expiration_tabula < moment().format()
      ) {
        window.location.href = "/choose-plan?solution=TabulaRx";
      } else {
        $("#tabula-modal").modal("show");
      }
    }
    if (solution === "DoorstepRx") {
      if (
        (userInfo.expiration_doorstep &&
          userInfo.expiration_doorstep >= moment().format()) ||
        this.props.auth.user.superadmin
      ) {
        window.location.href =
          "https://doorstep.psgarx.com?goto=dashboard&reference=" +
          localStorage.getItem("jwtToken");
      } else if (
        userInfo.expiration_doorstep &&
        userInfo.expiration_doorstep < moment().format()
      ) {
        window.location.href = "/choose-plan?solution=DoorstepRx";
      } else {
        $("#doorstep-modal").modal("show");
      }
    }
    if (solution === "RxMinder") {
      if (
        (userInfo.expiration_rxminder &&
          userInfo.expiration_rxminder >= moment().format()) ||
        this.props.auth.user.superadmin
      ) {
        window.location.href =
          "https://rxminder.psgarx.com?goto=dashboard&reference=" +
          localStorage.getItem("jwtToken");
      } else if (
        userInfo.expiration_rxminder &&
        userInfo.expiration_rxminder < moment().format()
      ) {
        window.location.href = "/choose-plan?solution=RxMinder";
      } else {
        $("#rxminder-modal").modal("show");
      }
    }
    if (solution === "animalMed") {
      window.location.href =
        "https://animalmedrx.com?goto=dashboard&reference=" +
        localStorage.getItem("jwtToken");
    }
    if (solution === "RxPodcast") {
      if (
        (userInfo.expiration_rxminder &&
          userInfo.expiration_rxminder >= moment().format()) ||
        this.props.auth.user.superadmin
      ) {
        this.props.history.push("/solution-podcast");
      } else {
        this.props.history.push("/choose-plan?solution=RxPodcast");
      }
    }
    if (solution === "AlereRx") {
      if (
        (userInfo.expiration_alererx &&
          userInfo.expiration_alererx >= moment().format()) ||
        this.props.auth.user.superadmin
      ) {
        this.props.history.push("/solution-alererx");
      } else {
        this.props.history.push("/choose-plan?solution=AlereRx");
      }
    }
    if (solution === "RxReach") {
      if (
        (userInfo.expiration_rxreach &&
          userInfo.expiration_rxreach >= moment().format()) ||
        this.props.auth.user.superadmin
      ) {
        this.props.history.push("/solution-rxreach");
      } else {
        this.props.history.push("/choose-plan?solution=RxReach");
      }
    }
    if (solution === "XPedicareRx") {
      if (
        (userInfo.expiration_xpedicare24 &&
          userInfo.expiration_xpedicare24 >= moment().format()) ||
        (userInfo.expiration_xpedicare &&
          userInfo.expiration_xpedicare >= moment().format()) ||
        this.props.auth.user.superadmin
      ) {
        this.props.history.push("/solution-xpedicare");
      } else {
        this.props.history.push("/choose-plan?solution=xpedicare24");
      }
    }
    if (solution === "diabuddy") {
      this.props.history.push("/solution-diabuddy");
    }
    if (solution === "glp") {
      window.open(
        "https://buy.glp1rxdirect.com/login",
        "_blank" // <- This is what makes it open in a new window.
      );
    }

    if (solution === "Marketing") {
      window.open(
        "https://psgaonline.com/",
        "_blank" // <- This is what makes it open in a new window.
      );
    }
    if (solution === "digitalSolution") {
      window.open(
        "https://login.rxconnexion.com/login/",
        "_blank" // <- This is what makes it open in a new window.
      );
    }

    if (solution === "BonaRx") {
      if (this.props.auth.user.superadmin) {
        window.location.href =
          "https://analytics.psgarx.com?goto=dashboard&reference=" +
          localStorage.getItem("jwtToken");
      } else {
        if (
          userInfo.expiration_bonarx &&
          userInfo.expiration_bonarx >= moment().format()
        ) {
          if (this.props.auth.user.rx_group === undefined) {
            this.props.history.push("/solution-bonarx");
          } else {
            window.location.href =
              "https://analytics.psgarx.com?goto=dashboard&reference=" +
              localStorage.getItem("jwtToken");
          }
        } else {
          this.props.history.push("/choose-plan?solution=BonaRx");
        }
      }
    }
  };

  render() {
    const { subscriptionList } = this.state;
    return (
      <>
        <ProSidebar collapsed={this.state.collapsed}>
          {this.state.collapsed && <style>{css}</style>}
          <SidebarHeader>
            {!this.state.collapsed && (
              <NavLink activeClassName="is-active" to="/">
                {location.includes(window.location.pathname) ? (
                  <img src={psga} alt="Logo" />
                ) : (
                  <img src={bona} alt="logo" />
                )}
              </NavLink>
            )}
            <FontAwesomeIcon icon={faBars} onClick={this.handleToggleSidebar} />
          </SidebarHeader>

          {location.includes(window.location.pathname) ? (
            <>
              <SidebarContent>
                <Menu iconShape="square">

                  <MenuItem
                    icon={<><div
                      title={"Subscribed"}
                      className={
                        subscriptionList.includes("TabulaRx")
                          ? "green-circle"
                          : "red-circle"
                      }
                    /><FontAwesomeIcon icon={faPrescriptionBottle} /></>}
                    onClick={() => this.redirectTo("TabulaRx")}
                  >

                    TabulaRx
                  </MenuItem>
                  <MenuItem
                    icon={<><div
                      className={
                        subscriptionList.includes("DoorstepRx")
                          ? "green-circle"
                          : "red-circle"
                      }
                    /><FontAwesomeIcon icon={faTruck} /></>}
                    onClick={() => this.redirectTo("DoorstepRx")}
                  >

                    DoorstepRx
                  </MenuItem>
                  <MenuItem
                    icon={<><div
                      className={
                        subscriptionList.includes("RxReach")
                          ? "green-circle"
                          : "red-circle"
                      }
                    /><FontAwesomeIcon icon={faEnvelope} /></>}
                    onClick={() => this.redirectTo("RxReach")}
                  >

                    RxReach
                  </MenuItem>
                  <MenuItem
                    icon={<> <div
                      className={
                        subscriptionList.includes("RxMinder")
                          ? "green-circle"
                          : "red-circle"
                      }
                    /><FontAwesomeIcon icon={faEnvelope} /></>}
                    onClick={() => this.redirectTo("RxMinder")}
                  >

                    RxMinder
                  </MenuItem>
                  <MenuItem
                    icon={<> <div
                      className={
                        subscriptionList.includes("RxPodcast")
                          ? "green-circle"
                          : "red-circle"
                      }
                    /><FontAwesomeIcon icon={faPodcast} /></>}
                    onClick={() => this.redirectTo("RxPodcast")}
                  >

                    RxPodcast
                  </MenuItem>
                  <MenuItem
                    icon={<><div
                      className={
                        subscriptionList.includes("BonaRx")
                          ? "green-circle"
                          : "red-circle"
                      }
                    /><FontAwesomeIcon icon={faChartPie} /></>}
                    onClick={() => this.redirectTo("BonaRx")}
                  >

                    BonaRx
                  </MenuItem>
                  <MenuItem
                    icon={<>  <div
                      className={
                        subscriptionList.includes("AlereRx")
                          ? "green-circle"
                          : "red-circle"
                      }
                    /><FontAwesomeIcon icon={faHospital} /></>}
                    onClick={() => this.redirectTo("AlereRx")}
                  >

                    AlereRx
                  </MenuItem>
                  <MenuItem
                    icon={<><div
                      className={
                        subscriptionList.includes("xpedicare24") ||
                          subscriptionList.includes("xpedicare")
                          ? "green-circle"
                          : "red-circle"
                      }
                    /><FontAwesomeIcon icon={faPrescriptionBottle} /></>}
                    onClick={() => this.redirectTo("XPedicareRx")}
                  >

                    XPedicareRx 24
                  </MenuItem>
                  <MenuItem
                    icon={<><div className={"green-circle"} /><FontAwesomeIcon icon={faShoppingCart} /></>}
                    onClick={() => this.redirectTo("Marketing")}
                  >

                    Marketing
                  </MenuItem>
                  <MenuItem
                    icon={<> <div className={"green-circle"} /><FontAwesomeIcon icon={faShoppingCart} /></>}
                    onClick={() => this.redirectTo("digitalSolution")}
                  >

                    Digital Solutions
                  </MenuItem>

                  <MenuItem
                    icon={<><div className={"green-circle"} /><FontAwesomeIcon icon={faHandHoldingHeart} /></>}
                    onClick={() => this.redirectTo("glp")}
                  >

                    GLP-1RxDirect
                  </MenuItem>
                  <MenuItem
                    icon={<><div className={"green-circle"} /><FontAwesomeIcon icon={faCapsules} /></>}
                    onClick={() => this.redirectTo("animalMed")}
                  >

                    Animal Med
                  </MenuItem>
                  <MenuItem icon={<><div className={"green-circle"} /><FontAwesomeIcon icon={faBook} /></>}>
                    <NavLink
                      activeClassName="is-active"
                      to="/solution-coaching"
                      className="list-group-item left list-group-item-action coaching"
                    >
                      Coaching
                    </NavLink>
                  </MenuItem>
                </Menu>
              </SidebarContent>
              <SidebarFooter style={{ textAlign: "center" }}>
                <Menu iconShape="square">
                  <MenuItem icon={<img src={"https://rxreachanalytis.s3.amazonaws.com/logo/contact.png"} className="contact-icon" />}>
                    <NavLink
                      activeClassName="is-active"
                      to="/support"
                      className="list-group-item list-group-item-action left"
                    >
                      Support
                    </NavLink>
                  </MenuItem>
                  <hr />
                  <MenuItem>
                    <div className="p-1">
                      Copyright {moment().year()}
                      <a className="text-white" href="https://rxconnexion.com/">
                        {" "}
                        PSGA, Inc
                      </a>
                    </div>
                    <div className="">
                      <a
                        className="text-white"
                        href="https://www.rxconnexion.com/privacy"
                      >
                        {" "}
                        Privacy Policy
                      </a>
                    </div>
                  </MenuItem>
                </Menu>
              </SidebarFooter>
            </>
          ) : (
            <>
              <SidebarContent>
                <Menu iconShape="square">
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faClinicMedical} />}
                    onClick={(e) => this.collapseOther(e, "parent")}
                  >
                    <NavLink
                      activeClassName="is-active"
                      to="/dashboard"
                      className="list-group-item list-group-item-action"
                    >
                      Dashboard
                    </NavLink>
                  </MenuItem>

                  <SubMenu
                    title="CS Manger"
                    icon={<FontAwesomeIcon icon={faChartBar} />}
                    onClick={(e) => this.collapseOther(e, "")}
                  >
                    <MenuItem icon={<FontAwesomeIcon icon={faShoppingCart} />}>
                      <NavLink
                        activeClassName="is-active"
                        to="/purchases"
                        className="list-group-item list-group-item-action"
                        onClick={(e) => this.collapseOther(e, "child")}
                      >
                        Purchases
                      </NavLink>
                    </MenuItem>
                    <MenuItem icon={<FontAwesomeIcon icon={faShoppingCart} />}>
                      <NavLink
                        activeClassName="is-active"
                        to="/dispense"
                        className="list-group-item list-group-item-action"
                        onClick={(e) => this.collapseOther(e, "child")}
                      >
                        Dispense
                      </NavLink>
                    </MenuItem>
                    <MenuItem icon={<FontAwesomeIcon icon={faShoppingCart} />}>
                      <NavLink
                        activeClassName="is-active"
                        to="/reverse-distribution"
                        className="list-group-item list-group-item-action"
                        onClick={(e) => this.collapseOther(e, "child")}
                      >
                        {"  "}Reverse Distribution
                      </NavLink>
                    </MenuItem>
                    <MenuItem icon={<FontAwesomeIcon icon={faCalculator} />}>
                      <NavLink
                        activeClassName="is-active"
                        to="/mme-calculator"
                        className="list-group-item list-group-item-action"
                        onClick={(e) => this.collapseOther(e, "child")}
                      >
                        {"  "}MME Calculator
                      </NavLink>
                    </MenuItem>
                    <SubMenu
                      title="CS Reports"
                      icon={<FontAwesomeIcon icon={faChartBar} />}
                      id="report"
                    >
                      {!this.props.auth.user.superadmin &&
                        !this.props.auth.user.multistore && (
                          <MenuItem>
                            <NavLink
                              activeClassName="is-active"
                              to="/biennial-form"
                              className="list-group-item list-group-item-action"
                              onClick={(e) => this.collapseOther(e, "childchild")}
                            >
                              <FontAwesomeIcon icon={faNotesMedical} />
                              {"  "}Biennial Form
                            </NavLink>
                          </MenuItem>
                        )}
                      {!this.props.auth.user.superadmin && (
                        <MenuItem>
                          <NavLink
                            activeClassName="is-active"
                            to="/inventory-reconciliation"
                            className="list-group-item list-group-item-action"
                            onClick={(e) => this.collapseOther(e, "childchild")}
                          >
                            <FontAwesomeIcon icon={faPrescriptionBottle} />
                            {"  "}Inventory Reconciliation
                          </NavLink>
                        </MenuItem>
                      )}
                      <MenuItem>
                        <NavLink
                          activeClassName="is-active"
                          to="/reports-ahfs"
                          className="list-group-item list-group-item-action"
                          onClick={(e) => this.collapseOther(e, "childchild")}
                        >
                          <FontAwesomeIcon icon={faChartPie} />
                          {"  "}Report by Therapeutic class
                        </NavLink>
                      </MenuItem>
                      <MenuItem>
                        <NavLink
                          activeClassName="is-active"
                          to="/reports-presciber"
                          className="list-group-item list-group-item-action"
                          onClick={(e) => this.collapseOther(e, "childchild")}
                        >
                          <FontAwesomeIcon icon={faUserMd} />
                          {"  "}Physician Report
                        </NavLink>
                      </MenuItem>
                      <MenuItem>
                        <NavLink
                          activeClassName="is-active"
                          to="/reports-mme"
                          className="list-group-item list-group-item-action"
                          onClick={(e) => this.collapseOther(e, "childchild")}
                        >
                          <FontAwesomeIcon icon={faChartPie} />
                          {"  "}Morphine Milligram Equivalent(MME)
                        </NavLink>
                      </MenuItem>
                    </SubMenu>
                  </SubMenu>

                  <SubMenu
                    title="Lookup"
                    icon={<FontAwesomeIcon icon={faDatabase} />}
                    onClick={(e) => this.collapseOther(e, "")}
                  >
                    {this.props.auth.user.superadmin && (
                      <MenuItem>
                        <NavLink
                          activeClassName="is-active"
                          to="/users"
                          className="list-group-item list-group-item-action"
                          onClick={(e) => this.collapseOther(e, "child")}
                        >
                          <FontAwesomeIcon icon={faUser} />
                          {"  "}Groups
                        </NavLink>
                      </MenuItem>
                    )}

                    <MenuItem>
                      <NavLink
                        activeClassName="is-active"
                        to="/data-2000"
                        className="list-group-item list-group-item-action"
                        onClick={(e) => this.collapseOther(e, "child")}
                      >
                        <FontAwesomeIcon icon={faUserMd} />
                        {"  "}OUD MD LIST
                      </NavLink>
                    </MenuItem>
                    <MenuItem onClick={(e) => this.collapseOther(e, "parent")}>
                      <NavLink
                        activeClassName="is-active"
                        to="/hcpcs"
                        className="list-group-item list-group-item-action"
                        onClick={(e) => this.collapseOther(e, "child")}
                      >
                        <FontAwesomeIcon icon={faChartLine} />
                        {"  "}HCPCS
                      </NavLink>
                    </MenuItem>
                    <MenuItem onClick={(e) => this.collapseOther(e, "parent")}>
                      <a
                        href="https://apps.deadiversion.usdoj.gov/webforms2/spring/validationLogin?execution=e1s1"
                        target="_blank"
                        className="list-group-item list-group-item-action"
                        onClick={(e) => this.collapseOther(e, "child")}
                      >
                        <FontAwesomeIcon icon={faUserMd} />
                        {"  "}DEA Lookup
                      </a>
                    </MenuItem>
                    <MenuItem onClick={(e) => this.collapseOther(e, "parent")}>
                      <NavLink
                        activeClassName="is-active"
                        to="/oig-tookit"
                        className="list-group-item list-group-item-action"
                        onClick={(e) => this.collapseOther(e, "child")}
                      >
                        <FontAwesomeIcon icon={faUserMd} />
                        {"  "}OIG Toolkit
                      </NavLink>
                    </MenuItem>
                    <MenuItem onClick={(e) => this.collapseOther(e, "parent")}>
                      <NavLink
                        activeClassName="is-active"
                        to="/otps"
                        className="list-group-item list-group-item-action"
                        onClick={(e) => this.collapseOther(e, "child")}
                      >
                        <FontAwesomeIcon icon={faUserMd} />
                        {"  "}OTP Directory
                      </NavLink>
                    </MenuItem>
                  </SubMenu>
                  <SubMenu
                    title="Drugs"
                    icon={<FontAwesomeIcon icon={faCapsules} />}
                    id="drugs"
                  >
                    <MenuItem>
                      <NavLink
                        activeClassName="is-active"
                        to="/animal-drug"
                        className="list-group-item list-group-item-action"
                        onClick={(e) => this.collapseOther(e, "child")}
                      >
                        <FontAwesomeIcon icon={faChartLine} />
                        {"  "}Animal Drug List
                      </NavLink>
                    </MenuItem>
                    <MenuItem>
                      <NavLink
                        activeClassName="is-active"
                        to="/nadac"
                        className="list-group-item list-group-item-action"
                        onClick={(e) => this.collapseOther(e, "child")}
                      >
                        <FontAwesomeIcon icon={faUserMd} />
                        {"  "}NADAC
                      </NavLink>
                    </MenuItem>
                  </SubMenu>
                  <SubMenu
                    title="Tools"
                    icon={<FontAwesomeIcon icon={faTools} />}
                    onClick={(e) => this.collapseOther(e, "")}
                  >
                    <MenuItem>
                      <NavLink
                        activeClassName="is-active"
                        to="/business-tool"
                        className="list-group-item list-group-item-action"
                        onClick={(e) => this.collapseOther(e, "child")}
                      >
                        <FontAwesomeIcon icon={faChartBar} />
                        {"  "}Business Analytics
                      </NavLink>
                    </MenuItem>
                    <MenuItem>
                      <NavLink
                        activeClassName="is-active"
                        to="/compound-log"
                        className="list-group-item list-group-item-action"
                        onClick={(e) => this.collapseOther(e, "child")}
                      >
                        <FontAwesomeIcon icon={faCapsules} />
                        {"  "}Compound Log
                      </NavLink>
                    </MenuItem>
                    <MenuItem>
                      <a
                        href="https://fis.fda.gov/sense/app/ca606d81-3f9b-4480-9e47-8a8649da6470/sheet/dfa2f0ce-4940-40ff-8d90-d01c19ca9c4d/state/analysis?utm_medium=email&utm_source=govdelivery"
                        target="_blank"
                        className="list-group-item list-group-item-action"
                        onClick={(e) => this.collapseOther(e, "child")}
                      >
                        <FontAwesomeIcon icon={faUserNurse} />
                        {"  "}REMS Dashboard
                      </a>
                    </MenuItem>
                    <MenuItem>
                      <a
                        href={pdfAudit}
                        target="_blank"
                        className="list-group-item list-group-item-action"
                        onClick={(e) => this.collapseOther(e, "child")}
                      >
                        Rx Self Audit
                      </a>
                    </MenuItem>
                    {/* <MenuItem>
                    <NavLink
                      activeClassName="is-active"
                      to="/xpedicare-custom"
                      className="list-group-item list-group-item-action"
                      onClick={(e) => this.collapseOther(e, "child")}
                    >
                      <FontAwesomeIcon icon={faPrescription} />
                      {"  "}Xpedicare Custom Links
                    </NavLink>
                  </MenuItem> */}
                  </SubMenu>
                  <SubMenu
                    title="Resources"
                    icon={<FontAwesomeIcon icon={faBriefcase} />}
                    onClick={(e) => this.collapseOther(e, "")}
                  >
                    <MenuItem>
                      <a
                        href="https://apps.deadiversion.usdoj.gov/rxaor/spring/main?execution=e1s1"
                        target="_blank"
                        className="list-group-item list-group-item-action"
                      >
                        Rx Abuse Reporting
                      </a>
                    </MenuItem>
                    <MenuItem>
                      <a
                        href="https://www.deadiversion.usdoj.gov/meth/index.html"
                        target="_blank"
                        className="list-group-item list-group-item-action"
                      >
                        CMEA Certification
                      </a>
                    </MenuItem>
                    <MenuItem>
                      <a
                        href="https://www.deadiversion.usdoj.gov/GDP/(DEA-DC-046R1)(EO-DEA154R1)_Pharmacist's_Manual_DEA.pdf"
                        target="_blank"
                        className="list-group-item list-group-item-action"
                      >
                        DEA Pharmacist Manual
                      </a>
                    </MenuItem>
                    <MenuItem>
                      <a
                        href="https://apps.deadiversion.usdoj.gov/pubdispsearch/spring/main?execution=e1s1"
                        target="_blank"
                        className="list-group-item list-group-item-action"
                      >
                        Rx Disposal search
                      </a>
                    </MenuItem>
                    <MenuItem>
                      <a
                        href="https://apps.deadiversion.usdoj.gov/webforms2/spring/renewalLogin?execution=e1s1"
                        target="_blank"
                        className="list-group-item list-group-item-action"
                      >
                        DEA Renewal
                      </a>
                    </MenuItem>
                    <MenuItem>
                      <a
                        href="https://apps.deadiversion.usdoj.gov/TLR/"
                        target="_blank"
                        className="list-group-item list-group-item-action"
                      >
                        DEA 106
                      </a>
                    </MenuItem>
                  </SubMenu>

                  {/* <MenuItem icon={<FontAwesomeIcon icon={faClinicMedical} />}>
                  <NavLink
                    activeClassName="is-active"
                    to="/compare-rx"
                    className="list-group-item list-group-item-action"
                  >
                    CompareRx
                  </NavLink>
                </MenuItem> */}
                  {this.props.auth.user.superadmin && (
                    <MenuItem
                      onClick={(e) => this.collapseOther(e, "parent")}
                      icon={<FontAwesomeIcon icon={faUserMd} />}
                    >
                      <NavLink
                        activeClassName="is-active"
                        to="/drug-add-user"
                        className="list-group-item list-group-item-action"
                      >
                        Drug Added By User
                      </NavLink>
                    </MenuItem>
                  )}
                  {this.props.auth.user.superadmin && (
                    <SubMenu
                      title="Email Automation"
                      icon={<FontAwesomeIcon icon={faMailBulk} />}
                      onClick={(e) => this.collapseOther(e, "")}
                    >
                      <MenuItem icon={<FontAwesomeIcon icon={faUser} />}>
                        <NavLink
                          activeClassName="is-active"
                          to="/group-email"
                          className="list-group-item list-group-item-action"
                        >
                          Group Emails
                        </NavLink>
                      </MenuItem>
                      <MenuItem icon={<FontAwesomeIcon icon={faReceipt} />}>
                        <NavLink
                          activeClassName="is-active"
                          to="/email-templates"
                          className="list-group-item list-group-item-action"
                        >
                          Email Templates
                        </NavLink>
                      </MenuItem>
                    </SubMenu>
                  )}
                </Menu>
              </SidebarContent>
              <SidebarFooter style={{ textAlign: "center" }}>
                <Menu iconShape="square">
                  <MenuItem icon={<img src={"https://rxreachanalytis.s3.amazonaws.com/logo/contact.png"} className="contact-icon" />}>
                    <NavLink
                      activeClassName="is-active"
                      to="/support"
                      className="list-group-item list-group-item-action left"
                    >
                      Support
                    </NavLink>
                  </MenuItem>
                  <hr />
                  <MenuItem>
                    <div className="p-1">
                      Copyright {moment().year()}
                      <a className="text-white" href="https://rxconnexion.com/">
                        {" "}
                        PSGA, Inc
                      </a>
                    </div>
                    <div className="">
                      <a
                        className="text-white"
                        href="https://www.rxconnexion.com/privacy"
                      >
                        {" "}
                        Privacy Policy
                      </a>
                    </div>
                  </MenuItem>
                </Menu>
              </SidebarFooter>
            </>
          )}
        </ProSidebar>
        {((this.props.auth.user.expiration_tabula &&
          this.props.auth.user.expiration_tabula >= moment().format()) ||
          this.props.auth.user.superadmin) &&
          <Helmet>
            <script
              src="https://cdn.apigateway.co/webchat-client..prod/sdk.js"
              data-widget-id="eee4bb40-84ed-11ef-a9bb-1a3da0fa9c8b"
              defer
            ></script>
          </Helmet>
        }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default withRouter(connect(mapStateToProps, { logoutUser })(Sidebar));
