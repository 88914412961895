import "bootstrap";
import "jquery/dist/jquery.min";
import "popper.js/dist/popper";
import queryString from "query-string";
import React, { Component } from "react";
import IdleTimer from "react-idle-timer";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/font-awesome/css/font-awesome.css";
import "./App.css";
import {
  logoutUser,
  setGroups,
  setmultistoreData,
} from "./actions/authActions";
import Forgot from "./components/auth/Forgot";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import ResetPassword from "./components/auth/ResetPassword";
import NotFound from "./components/layout/NotFound";
import AnimalDrug from "./components/pages/AnimalDrug";
import BiennialForm from "./components/pages/BiennialForm";
import BusinessTool from "./components/pages/BusinessTool";
import CardInfo from "./components/pages/CardInfo";
import CashReport from "./components/pages/CashReport";
import ChoosePlan from "./components/pages/ChoosePlan";
import ChoosePlanInitial from "./components/pages/ChoosePlanInitial";
import CompareRx from "./components/pages/CompareRx";
import CompoundLog from "./components/pages/CompoundLog";
import CompoundLogList from "./components/pages/CompoundLogList";
import Coupons from "./components/pages/Coupons";
import Credits from "./components/pages/Credits";
import Dashboard from "./components/pages/Dashboard";
import Data2000 from "./components/pages/Data2000";
import Dea from "./components/pages/Dea";
import Dispense from "./components/pages/Dispense";
import DispenseDeleteRequest from "./components/pages/DispenseDeleteRequest";
import Doctors from "./components/pages/Doctors";
import DrugByUser from "./components/pages/DrugByUser";
import DrugUtilization from "./components/pages/DrugUtilization";
import Drugs from "./components/pages/Drugs";
import Expand from "./components/pages/Expand";
import ExpandNew from "./components/pages/ExpandNew";
import ExpandRx from "./components/pages/ExpandRx";
import FaxCredits from "./components/pages/FaxCredits";
import FaxTemplate from "./components/pages/FaxTemplate";
import FaxTemplateLog from "./components/pages/FaxTemplateLog";
import FridgeLog from "./components/pages/FridgeLog";
import GroupEmail from "./components/pages/GroupEmail";
import GroupEmailTemplates from "./components/pages/GroupEmailTemplates";
import Hcpcs from "./components/pages/Hcpcs";
import InternalDoctor from "./components/pages/InternalDoctor";
import InventoryReconciliation from "./components/pages/InventoryReconciliation";
import Landing from "./components/pages/Landing";
import LandingNew from "./components/pages/LandingNew";
import MmeCaclulator from "./components/pages/MmeCaclulator";
import MmeReport from "./components/pages/MmeReport";
import Nadac from "./components/pages/Nadac";
import Notifications from "./components/pages/Notifications";
import OigToolkit from "./components/pages/OigToolkit";
import OpenAi from "./components/pages/OpenAi";
import OtpDirectory from "./components/pages/OtpDirectory";
import PrescriberReport from "./components/pages/PrescriberReport";
import PrescriberRx from "./components/pages/PrescriberRx";
import PrescriberRxLocal from "./components/pages/PrescriberRxLocal";
import PriceCompare from "./components/pages/PriceCompare";
import Profile from "./components/pages/Profile";
import PurchaseDeleteRequest from "./components/pages/PurchaseDeleteRequest";
import Purchases from "./components/pages/Purchases";
import ReportsAhfs from "./components/pages/ReportsAhfs";
import ReverseDistribution from "./components/pages/ReverseDistribution";
import RxAreaLog from "./components/pages/RxAreaLog";
import SavedSearch from "./components/pages/SavedSearch";
import SubUsers from "./components/pages/SubUsers";
import Subscribe from "./components/pages/Subscribe";
import Transaction from "./components/pages/Transaction";
import Users from "./components/pages/Users";
import Drug from "./components/pages/View/Drug";
import LoginHistory from "./components/pages/View/LoginHistory";
import Npis from "./components/pages/View/Npis";
import User from "./components/pages/View/User";
import XpedicareCustom from "./components/pages/XpedicareCustom/Index";
import support from "./components/pages/support";
import ConfigSetting from "./components/partials/ConfigSetting";
import Navbar from "./components/partials/Navbar";
import Sidebar from "./components/partials/Sidebar";
import UploadDispense from "./components/partials/UploadDispense";
import UserSettings from "./components/partials/UserSettings";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Alererx from "./components/solution/Alererx";
import Bonarx from "./components/solution/Bonarx";
import Coaching from "./components/solution/Coaching";
import DiaBuddy from "./components/solution/Diabuddy";
import MedDrugLookup from "./components/solution/MedDrugLookup";
import RxPodcast from "./components/solution/RxPodcast";
import Rxconnexion from "./components/solution/Rxconnexion";
import Rxreach from "./components/solution/Rxreach";
import Tabularx from "./components/solution/Tabularx";
import Xpedicare from "./components/solution/Xpedicare";
import Doorstep from "./components/welcome/Doorstep";
import RxMinder from "./components/welcome/RxMinder";
import Tabula from "./components/welcome/Tabula";
import CouponsOpen from "./components/pages/CouponsOpen";
class RouteData extends Component {
  componentDidMount() {
    if (this.props.records === undefined && this.props.auth.user.superadmin) {
      this.props.setGroups();
    }
    if (
      this.props.auth.user.multistore &&
      this.props.multistoreData.length === 0
    ) {
      this.props.setmultistoreData(this.props.auth.user.email);
    }
    if (queryString.parse(window.location.search).logout) {
      this.props.logoutUser(this.props.history);
    }
    if (
      queryString.parse(window.location.search).reference &&
      queryString.parse(window.location.search).reference.includes("Bearer")
    ) {
      localStorage.setItem(
        "jwtToken",
        queryString.parse(window.location.search).reference
      );
      window.location.href = "/dashboard";
    }
    if (
      this.props.auth.isAuthenticated &&
      (this.props.auth.user.email === "david.bigoness@animalmedexpress.com" ||
        this.props.auth.user.email === "cortney@animalmedexpress.com")
    ) {
      window.location.href =
        "https://animalmedrx.com?goto=dashboard&reference=" +
        localStorage.getItem("jwtToken");
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.multistoreData.length !== prevProps.multistoreData.length) {
      this.props.setmultistoreData(this.props.auth.user.email);
    }
  }

  onLogoutClick = (e) => {
    this.props.logoutUser(this.props.history);
  };

  onIdle = (e) => {
    this.onLogoutClick();
  };

  render() {
    return (
      <div className="App">
        <IdleTimer onIdle={this.onIdle} debounce={250} timeout={21600000} />
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/coupons-internal-list" component={CouponsOpen} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot" component={Forgot} />
          <Route
            path="/password-reset/:userId/:token"
            component={ResetPassword}
          />
          <PrivateRoute exact path="/landing-new" component={LandingNew} />
          <PrivateRoute exact path="/choose-plan" component={ChoosePlan} />
          <PrivateRoute exact path="/card-info" component={CardInfo} />
          <PrivateRoute
            exact
            path="/choose-plan-initial"
            component={ChoosePlanInitial}
          />
          <div className="container-fluid">
            <div className="row" style={{ minHeight: "calc(100vh)" }}>
              <Sidebar />
              <Doorstep />
              <Tabula />
              <RxMinder />
              <div className="col" id="main" >
                <Navbar />
                <Switch>
                  <Switch>
                    {this.props.auth.user.superadmin && (
                      <>
                        <PrivateRoute exact path="/users" component={Users} />
                        <PrivateRoute
                          exact
                          path="/user/:_id"
                          component={User}
                        />
                        <PrivateRoute
                          exact
                          path="/loginHistory/:_id"
                          component={LoginHistory}
                        />
                        <PrivateRoute
                          exact
                          path="/landing"
                          component={Landing}
                        />
                        <PrivateRoute
                          exact
                          path="/dashboard"
                          component={Dashboard}
                        />
                        <PrivateRoute
                          exact
                          path="/prescriber-rx"
                          component={PrescriberRx}
                        />
                        <PrivateRoute
                          exact
                          path="/prescriberrx-local"
                          component={PrescriberRxLocal}
                        />
                        <PrivateRoute
                          exact
                          path="/cash-report"
                          component={CashReport}
                        />
                        <PrivateRoute exact path="/npis" component={Doctors} />
                        <PrivateRoute exact path="/drugs" component={Drugs} />
                        <PrivateRoute
                          exact
                          path="/purchases"
                          component={Purchases}
                        />
                        <PrivateRoute
                          exact
                          path="/dispense"
                          component={Dispense}
                        />
                        <PrivateRoute
                          exact
                          path="/inventory-reconciliation"
                          component={InventoryReconciliation}
                        />
                        <PrivateRoute
                          exact
                          path="/fax-logs"
                          component={FaxTemplateLog}
                        />
                        <PrivateRoute
                          exact
                          path="/mme-calculator"
                          component={MmeCaclulator}
                        />
                        <PrivateRoute
                          exact
                          path="/biennial-form"
                          component={BiennialForm}
                        />
                        <PrivateRoute
                          exact
                          path="/internal-physician"
                          component={InternalDoctor}
                        />
                        <PrivateRoute exact path="/dea" component={Dea} />
                        <PrivateRoute
                          exact
                          path="/reports-ahfs"
                          component={ReportsAhfs}
                        />
                        <PrivateRoute
                          exact
                          path="/reports-mme"
                          component={MmeReport}
                        />
                        <PrivateRoute
                          exact
                          path="/reports-presciber"
                          component={PrescriberReport}
                        />
                        <PrivateRoute
                          exact
                          path="/sub-users"
                          component={SubUsers}
                        />
                        <PrivateRoute
                          exact
                          path="/solution-bonarx"
                          component={Bonarx}
                        />
                        <PrivateRoute
                          exact
                          path="/solution-alererx"
                          component={Alererx}
                        />
                        <PrivateRoute
                          exact
                          path="/solution-rxreach"
                          component={Rxreach}
                        />
                        <PrivateRoute
                          exact
                          path="/solution-tabularx"
                          component={Tabularx}
                        />
                        <PrivateRoute
                          exact
                          path="/solution-rxconnexion"
                          component={Rxconnexion}
                        />
                        <PrivateRoute
                          exact
                          path="/solution-xpedicare"
                          component={Xpedicare}
                        />
                        <PrivateRoute
                          exact
                          path="/solution-diabuddy"
                          component={DiaBuddy}
                        />
                        <PrivateRoute
                          exact
                          path="/solution-podcast"
                          component={RxPodcast}
                        />
                        <PrivateRoute
                          exact
                          path="/solution-coaching"
                          component={Coaching}
                        />
                        <PrivateRoute
                          exact
                          path="/support"
                          component={support}
                        />
                        <PrivateRoute
                          exact
                          path="/drug-add-user"
                          component={DrugByUser}
                        />
                        <PrivateRoute
                          exact
                          path="/npis/:_id"
                          component={Npis}
                        />
                        <PrivateRoute
                          exact
                          path="/fridge-log"
                          component={FridgeLog}
                        />
                        <PrivateRoute
                          exact
                          path="/rxarea-log"
                          component={RxAreaLog}
                        />
                        <PrivateRoute
                          exact
                          path="/compound-log"
                          component={CompoundLog}
                        />
                        <PrivateRoute
                          exact
                          path="/compoundLog-list"
                          component={CompoundLogList}
                        />
                        <PrivateRoute
                          exact
                          path="/animal-drug"
                          component={AnimalDrug}
                        />
                        <PrivateRoute exact path="/nadac" component={Nadac} />
                        <PrivateRoute
                          exact
                          path="/drug-utilization"
                          component={DrugUtilization}
                        />
                        <PrivateRoute
                          exact
                          path="/oig-tookit"
                          component={OigToolkit}
                        />
                        <PrivateRoute
                          exact
                          path="/otps"
                          component={OtpDirectory}
                        />
                        <PrivateRoute exact path="/hcpcs" component={Hcpcs} />
                        <PrivateRoute
                          exact
                          path="/drug/:_id"
                          component={Drug}
                        />
                        <PrivateRoute
                          exact
                          path="/reverse-distribution"
                          component={ReverseDistribution}
                        />
                        <PrivateRoute
                          exact
                          path="/profile"
                          component={Profile}
                        />
                        <PrivateRoute
                          exact
                          path="/config"
                          component={ConfigSetting}
                        />
                        <PrivateRoute
                          exact
                          path="/group-email"
                          component={GroupEmail}
                        />
                        <PrivateRoute
                          exact
                          path="/email-templates"
                          component={GroupEmailTemplates}
                        />
                        <PrivateRoute
                          exact
                          path="/transactions"
                          component={Transaction}
                        />
                        <PrivateRoute
                          exact
                          path="/subscribe"
                          component={Subscribe}
                        />
                        <PrivateRoute
                          exact
                          path="/card-info"
                          component={CardInfo}
                        />
                        <PrivateRoute
                          exact
                          path="/credits"
                          component={Credits}
                        />

                        <PrivateRoute
                          exact
                          path="/dispense-delete-request"
                          component={DispenseDeleteRequest}
                        />
                        <PrivateRoute
                          exact
                          path="/purchase-delete-request"
                          component={PurchaseDeleteRequest}
                        />
                        <PrivateRoute
                          exact
                          path="/business-tool"
                          component={BusinessTool}
                        />
                        <PrivateRoute
                          exact
                          path="/user-settings"
                          component={UserSettings}
                        />
                        <PrivateRoute exact path="/expand" component={Expand} />
                        <PrivateRoute
                          exact
                          path="/expand-rx"
                          component={ExpandRx}
                        />
                        <PrivateRoute
                          exact
                          path="/expand-new"
                          component={ExpandNew}
                        />
                        <PrivateRoute
                          exact
                          path="/data-2000"
                          component={Data2000}
                        />
                        <PrivateRoute
                          exact
                          path="/coupons"
                          component={Coupons}
                        />
                        <PrivateRoute
                          exact
                          path="/upload-dispense"
                          component={UploadDispense}
                        />
                        <PrivateRoute
                          exact
                          path="/med-drug-lookup"
                          component={MedDrugLookup}
                        />
                        <PrivateRoute
                          exact
                          path="/fax-template"
                          component={FaxTemplate}
                        />
                      </>
                    )}
                    <PrivateRoute exact path="/landing" component={Landing} />
                    <PrivateRoute
                      exact
                      path="/dashboard"
                      component={Dashboard}
                    />
                    <PrivateRoute
                      exact
                      path="/prescriber-rx"
                      component={PrescriberRx}
                    />
                    <PrivateRoute
                      exact
                      path="/prescriberrx-local"
                      component={PrescriberRxLocal}
                    />
                    <PrivateRoute
                      exact
                      path="/cash-report"
                      component={CashReport}
                    />
                    <PrivateRoute exact path="/npis" component={Doctors} />
                    <PrivateRoute exact path="/drugs" component={Drugs} />
                    <PrivateRoute
                      exact
                      path="/purchases"
                      component={Purchases}
                    />
                    <PrivateRoute exact path="/dispense" component={Dispense} />
                    <PrivateRoute
                      exact
                      path="/inventory-reconciliation"
                      component={InventoryReconciliation}
                    />
                    <PrivateRoute
                      exact
                      path="/mme-calculator"
                      component={MmeCaclulator}
                    />
                    <PrivateRoute
                      exact
                      path="/biennial-form"
                      component={BiennialForm}
                    />
                    <PrivateRoute
                      exact
                      path="/internal-physician"
                      component={InternalDoctor}
                    />
                    <PrivateRoute exact path="/dea" component={Dea} />
                    <PrivateRoute
                      exact
                      path="/reports-ahfs"
                      component={ReportsAhfs}
                    />
                    <PrivateRoute
                      exact
                      path="/reports-mme"
                      component={MmeReport}
                    />
                    <PrivateRoute
                      exact
                      path="/reports-presciber"
                      component={PrescriberReport}
                    />
                    <PrivateRoute
                      exact
                      path="/sub-users"
                      component={SubUsers}
                    />
                    <PrivateRoute
                      exact
                      path="/solution-bonarx"
                      component={Bonarx}
                    />
                    <PrivateRoute
                      exact
                      path="/solution-alererx"
                      component={Alererx}
                    />
                    <PrivateRoute
                      exact
                      path="/solution-rxreach"
                      component={Rxreach}
                    />
                    <PrivateRoute
                      exact
                      path="/solution-tabularx"
                      component={Tabularx}
                    />
                    <PrivateRoute
                      exact
                      path="/solution-rxconnexion"
                      component={Rxconnexion}
                    />
                    <PrivateRoute
                      exact
                      path="/solution-xpedicare"
                      component={Xpedicare}
                    />
                    <PrivateRoute
                      exact
                      path="/solution-diabuddy"
                      component={DiaBuddy}
                    />
                    <PrivateRoute
                      exact
                      path="/solution-podcast"
                      component={RxPodcast}
                    />
                    <PrivateRoute
                      exact
                      path="/solution-coaching"
                      component={Coaching}
                    />
                    <PrivateRoute exact path="/support" component={support} />
                    <PrivateRoute
                      exact
                      path="/drug-add-user"
                      component={DrugByUser}
                    />
                    <PrivateRoute exact path="/npis/:_id" component={Npis} />
                    <PrivateRoute
                      exact
                      path="/fridge-log"
                      component={FridgeLog}
                    />
                    <PrivateRoute
                      exact
                      path="/rxarea-log"
                      component={RxAreaLog}
                    />
                    <PrivateRoute
                      exact
                      path="/compound-log"
                      component={CompoundLog}
                    />
                    <PrivateRoute
                      exact
                      path="/compoundLog-list"
                      component={CompoundLogList}
                    />
                    <PrivateRoute
                      exact
                      path="/animal-drug"
                      component={AnimalDrug}
                    />
                    <PrivateRoute exact path="/nadac" component={Nadac} />
                    <PrivateRoute
                      exact
                      path="/drug-utilization"
                      component={DrugUtilization}
                    />
                    <PrivateRoute
                      exact
                      path="/oig-tookit"
                      component={OigToolkit}
                    />
                    <PrivateRoute exact path="/otps" component={OtpDirectory} />
                    <PrivateRoute exact path="/hcpcs" component={Hcpcs} />
                    <PrivateRoute exact path="/drug/:_id" component={Drug} />
                    <PrivateRoute
                      exact
                      path="/reverse-distribution"
                      component={ReverseDistribution}
                    />
                    <PrivateRoute exact path="/profile" component={Profile} />
                    <PrivateRoute
                      exact
                      path="/transactions"
                      component={Transaction}
                    />
                    <PrivateRoute
                      exact
                      path="/subscribe"
                      component={Subscribe}
                    />
                    <PrivateRoute
                      exact
                      path="/card-info"
                      component={CardInfo}
                    />
                    <PrivateRoute exact path="/credits" component={Credits} />
                    <PrivateRoute
                      exact
                      path="/dispense-delete-request"
                      component={DispenseDeleteRequest}
                    />
                    <PrivateRoute
                      exact
                      path="/purchase-delete-request"
                      component={PurchaseDeleteRequest}
                    />
                    <PrivateRoute
                      exact
                      path="/business-tool"
                      component={BusinessTool}
                    />
                    <PrivateRoute
                      exact
                      path="/user-settings"
                      component={UserSettings}
                    />
                    <PrivateRoute exact path="/expand" component={Expand} />
                    <PrivateRoute
                      exact
                      path="/expand-rx"
                      component={ExpandRx}
                    />
                    <PrivateRoute
                      exact
                      path="/expand-new"
                      component={ExpandNew}
                    />
                    <PrivateRoute
                      exact
                      path="/data-2000"
                      component={Data2000}
                    />
                    <PrivateRoute exact path="/coupons" component={Coupons} />
                    <PrivateRoute
                      exact
                      path="/upload-dispense"
                      component={UploadDispense}
                    />
                    <PrivateRoute
                      exact
                      path="/med-drug-lookup"
                      component={MedDrugLookup}
                    />
                    <PrivateRoute exact path="/search" component={OpenAi} />
                    <PrivateRoute
                      exact
                      path="/notifications"
                      component={Notifications}
                    />
                    <PrivateRoute
                      exact
                      path="/price-compare"
                      component={PriceCompare}
                    />
                    <PrivateRoute
                      exact
                      path="/compare-rx"
                      component={CompareRx}
                    />
                    <PrivateRoute
                      exact
                      path="/fax-template"
                      component={FaxTemplate}
                    />
                    <PrivateRoute
                      exact
                      path="/fax-logs"
                      component={FaxTemplateLog}
                    />
                    <PrivateRoute
                      exact
                      path="/fax-credits"
                      component={FaxCredits}
                    />
                    <PrivateRoute
                      exact
                      path="/saved-search"
                      component={SavedSearch}
                    />
                    <PrivateRoute
                      exact
                      path="/xpedicare-custom"
                      component={XpedicareCustom}
                    />
                  </Switch>
                  <Route exact path="*" component={NotFound} />
                </Switch>
              </div>
            </div>
          </div>
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  groups: state.groups,
  multistoreData: state.multistoreData,
});

export default connect(mapStateToProps, {
  setGroups,
  logoutUser,
  setmultistoreData,
})(RouteData);
